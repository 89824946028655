.send-container {
    width: 100%;
    background-color: whitesmoke;
    margin: 1rem;
    font-size: 14px;
    display: flex;
    flex-direction: row;
}

.send-button{
    font-size: 14px;
    background: rgb(249,204,65);
    background: linear-gradient(0deg, rgba(249,204,65,1) 0%, rgba(249,204,65,1) 100%);
    color: #333;
}

.MuiFormControl-root{
    font-size: 10px;
}
.panel-resizable {
    resize: vertical;
    overflow: auto;
}

.panel {
    width: 100%;
    height: 100%;
    padding-left: 0.5rem;
    margin-left: 0.3rem;
    /* border: 2px solid rgb(255, 195, 44); */
    box-shadow: 1px 1px 1px 1px rgb(255, 191, 0);

}

.panel-view {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 95%;

}
.send-headers{
    text-align: center;
    padding-top: 0.4rem;
    width: 100%;
    background: rgb(231,231,231);
    background: linear-gradient(0deg, rgba(231,231,231,1) 0%, rgba(255,255,255,1) 100%);
}    

.send-form{
    border: 1px solid rgb(209, 209, 209);
}
.message-log-table{
    width: 100%;
    border: 1px solid grey;
}
.message-log-table thead{
    background: rgb(201,201,201);
    background: linear-gradient(352deg, rgba(201,201,201,1) 49%, rgba(255,255,255,1) 100%);   
    padding-top: 0.2rem;    
}
.right-side{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0.2rem;
    gap: 0.2rem;
}

.caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    caption-side: bottom;
    width: 100%;
    font-size: 10px;
}
.caption-footer{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    caption-side: bottom;
    width: 100%;
    font-size: 10px;
    font-style: italic;
}