.content-right {
    margin-top: 10rem;
    height: auto;
    width: 40%;
}
.form-title{
    color: #333;
} 
.signup-page{
    display: flex;
    flex-direction: row;
    background-image: url("../../../assets/images/bgi24.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding-top: 4rem;
    justify-content: space-between;
    padding-bottom: 5rem;
}
@media screen and (max-width:860px){
    .signup-page{
        display: flex;
        flex-direction: column;
    }
    .left-side{
        display: none;
    }
    
}
.left-side{
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    margin-left: 12rem;
    margin-bottom: 5rem;
    padding-bottom: 18rem;

}
.left-side{
    margin-top: 15rem;
    
}
.signup-text{
    font-size: 500;
    font-size: 14px !important;
}