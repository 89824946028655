.my-transactions-modal{
    background-color: whitesmoke;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgb(226, 226, 226) 56%, rgb(226, 225, 225) 100%);
    border: 2px solid black;

}
.my-transactions-modal-box{
   /* padding: 0.5rem; */
}
.my-transactions-modal-title{
    background: rgb(164, 164, 164);
    background: linear-gradient(0deg, rgba(164, 164, 164, 1) 51%, rgba(198, 198, 198, 1) 100%);
    padding: 1rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;


}
.my-transactions-modal-buttons {
    background: rgb(129, 129, 129);
    background: linear-gradient(0deg, rgba(129, 129, 129, 1) 56%, rgba(93, 92, 92, 1) 100%);
    width: 50%;
}
.my-transactions-modal-form-buttons{
    background: rgb(129, 129, 129);
    background: linear-gradient(0deg, rgba(129, 129, 129, 1) 56%, rgba(93, 92, 92, 1) 100%);
    width: 100%;
}

.my-transactions-buttons{
    background: rgb(129, 129, 129);
    background: linear-gradient(0deg, rgba(129, 129, 129, 1) 56%, rgba(93, 92, 92, 1) 100%);
    width: 20%; 
}
.my-transactions-modal-box-title{
    background: rgb(129, 129, 129);
    background: linear-gradient(0deg, rgba(129, 129, 129, 1) 56%, rgba(93, 92, 92, 1) 100%);
    color: white;
    text-align: center;
    padding: 0.2rem;
}

@media screen and (max-width:689px) {
    .my-transactions-modal{
        background-color: whitesmoke;
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgb(226, 226, 226) 56%, rgb(226, 225, 225) 100%);
        border: 0.2px solid black;
        width: 90%;
    
    }
}

.in-between{
    width: 100%;
    height:2rem;
    background: rgb(224,224,224);
    background: linear-gradient(0deg, rgba(224,224,224,1) 42%, rgba(255,255,255,1) 100%);
}