@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.content {
    font-family: 'Poppins', sans-serif !important;
    font-size: 25px !important;
}

.hero-img {
    position: static;
    width: 100%;
    height: 100%;
}


.animated {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.hero-content {
   background-color: white;
   background-image: url("../../../../assets/images/bg44.png");
   background-size: contain;
   background-repeat: no-repeat;
   height: 100%;
     /* background-size:contain; */
    /* background-repeat: no-repeat; */
    /* background-size:100%;
    background-color: #f9cc41;
    left: -348px;
    top: 197px;
    border-radius: 0px;
    /* 
    width: 100%;
    height: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 12rem;

}

.content-left {
    display: flex;
    flex-direction: column;
    color: rgb(57, 56, 56);
    /* color: whitesmoke; */
    padding-top: 1rem;
    padding-left: 4rem;
    margin: 1rem;
    row-gap: 30px;

    /* background-color: rgba(0, 0, 0, 0.5); */
    width: 50%;
    padding-bottom: 1rem;

}

.hero-text {
    /* font-size: 14px !important; */
    /* font-family:Quicksand,system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji */
    font-family:"Montserrat",sans-serif;
    font-weight: 600;
    line-height: 22px;
    font-size: 15px !important;
    font-weight: 400;
}
.hero-text-title{
    /* font-size: 16px !important; */
    /* font-family:Quicksand,system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji */
    font-family:"Montserrat",sans-serif;
    font-weight: bold; 
    line-height: 49px;
    font-size: 38px;
    margin-bottom: 18px;
}

.content-left .content-header {
    font-size: 30px !important;
    font-weight: bold;
    color: #333;
    margin-top: 4rem;
}

.right-content {
    margin-top: 5rem;
    width: 30% !important;
    height: 20rem;
    font-weight: bold !important;

}

.content-right-img {
    margin-top: 2rem;
    width: 100% !important;
    height: 100% !important;
    /* display: none; */
}

.content-right-text {
    margin-top: 20rem;
    text-align: center;
    background-color: #F9CC41;
    padding: 2rem;
    font-size: 20px !important;
    /* margin-top: ; */
}

.hero-button {
    background-color: #f9cc41 !important;
    /* border: 2px solid white !important;
    background-color: #ffb527; */
    color: #333;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* width: 15rem !important; */
    max-width: 30rem;
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative !important;
    text-transform: capitalize !important;
    border-radius: 5rem !important;
    /* padding: 1rem !important; */

    /* margin: 0.375rem;
    color: #333;
    text-transform: uppercase;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    /* border-radius: 0.125rem; */
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
    /* transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out; */
    /* padding: 0.84rem 2.14rem; */
    font-size: 14px !important;
}



.hero-button:hover {
    box-shadow: 5px 10px 8px #2c2c2c;
}

@media screen and (max-width:800px) {

    .content-left {
        width: 100%;
        margin: 0.5rem;
    }

    .hero-button {
        width: 70% !important;
    }

    .hero-content {
        display: flex !important;
        flex-direction: column !important;
        padding: 1rem;
        width: 100%;

    }


    .right-content {
        display:flex;
        flex-direction: column;
    }



}


.fade-in {
    animation: fadeIn 1s;
}

@keyframes fadeIn {

    0% {
        transform: translate(100%);
    }

    100% {
        transform: translate(0%);
    }


}

.animated {
    padding-top: 20px;
    margin-bottom: 15px;
    -webkit-animation-duration: 1s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

}

@-webkit-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);

    }

    40% {
        -webkit-transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);

    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}