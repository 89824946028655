.table {
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial;
    font-weight: bold;
}

.btn-group {
    gap: 1rem;


}

.modal-header {
    background-color: rgb(247, 191, 8);
    opacity: 0.9;
    color: #333;
}

.modal {
    top: 50%;
    left: 50%;
}

.btn-close {
    color: whitesmoke;
}

.table {}

.contact-table-headers {
    margin-top: 0.5rem;
    padding-top: 1rem;
    background: rgb(230, 230, 230);
    background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 100%);
    color: #333;
    opacity: 0.8;
    height: 5rem;
}
.table-data{
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: normal;
    line-height: 1.5;
}