@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #fcfcfc;
}

.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    box-shadow: none !important;
}

.navbar {
    height: 60px;
    background-color: #333333;
    position: relative;
    color: whitesmoke;
    position: fixed;
    width: 100%;
    box-shadow: none !important;
}

.menu-icon {
    display: none;
}
.logo{
    margin:0;
}

.logo-img {
    width: 22%;
    background-color: #fcfcfc;
    margin: 0;
}

.logo-img {
    width: 8rem;
    margin: 0.5rem;
}

@media screen {}

@media screen and (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }
}

@media screen and (max-width:600px) {


    .menu-icon {
        display: block;
        cursor: pointer;

    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 60px;
        background-color: #353636;
        width: 0;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;

    }

    .nav-elements.active {
        width: 270px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }

}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 50px;
}

.nav-elements ul a {
    font-size: 12px;
    font-weight: 400;
    color: whitesmoke;
    text-decoration: none;
}

.nav-elements ul a.active {
    color: #e1dbdb;
    font-weight: 500;
    position: relative;
}

.nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #080808;
}
.login-button{
    border: 2px solid #f9cc41;
    border-radius: 20px;
    padding: 0.7rem;
    color: #f9cc41 !important;
    font-weight: 500 !important;
}
.signup-button{
    /* border: 2px solid #f9cc41; */
    background-color: #f9cc41;
    border-radius: 20px;
    padding: 0.7rem; 
    color: #333333 !important;
    font-weight: 500 !important;
    margin: 0px !important;
}