/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Roboto');
:root {
  --black: #333;
  --yellow: #f9cc41;

}

* {
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500; */
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; */
  font-weight: normal;
  line-height: 1.5;
  /* color: #0a0a0a; */
  -webkit-font-smoothing: antialiased;
  font-size: 11px;
  /* font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif; */
  /* font-weight:500; */
}

.sidebar-menu {
  top: 0;
  margin-top: 0rem;

}

.sidebar-menu>*:first-child {
  align-self: stretch;
}

.page-contents {
  width: 100%;
}

#app {
  /* background: rgb(255,255,255);
  background: linear-gradient(273deg, rgba(255,255,255,1) 0%, rgba(249,204,65,0.16992734593837533) 100%); */
  background-color: whitesmoke;
  color: #333;
  /* background-color: white; */
}

.app-buttons {
  background: rgb(249, 204, 65);
  background: linear-gradient(273deg, rgba(249, 204, 65, 1) 100%, rgba(255, 255, 255, 1) 100%);
  border: 2px solid #f9cc41;
  color: #333;
  border-radius: 2px;
  /* background: rgb(247,247,247);
  background: linear-gradient(0deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 100%);
  color: #333; */
  /* border:2px solid #f9cc41; */
  height: 2.5rem;
  padding: 0.2rem;
}

.app-modal-buttons {

  background: rgb(249, 204, 65);
  background: linear-gradient(273deg, rgba(249, 204, 65, 1) 100%, rgba(255, 255, 255, 1) 100%);
  border: 2px solid #f9cc41;
  color: #333;
  border-radius: 2px;
  /* background: rgb(247,247,247);
  background: linear-gradient(0deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 100%);
  color: #333; */
  /* border:2px solid #f9cc41; */
  height: 2.5rem;
  padding: 0.5rem;
  margin: 0.5rem;

}
.mui-data-grid{
  font-size: 10px;
}