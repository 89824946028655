@import url('https://fonts.googleapis.com/css?family=Montserrat');
.content{
    margin-top: 0px !important;
    padding-top: 0px !important;
    background-color: #e8e8e8e8;
}

.col-left{
    background: none !important;
}

.about-left {
    /* background-color: rgb(250, 202, 79); */
    /* background-image: url('../../../../assets/images/bgi11.png'); */
    /* background-size: cover; */
    /* width: 16rem; */
    background-color: #f9cc41;
    padding: 1rem;
    margin: 0.5rem;
    height: auto !important;
    text-align: center;
    border-radius: 5px !important;

}
.flex-item{
    width: 50%;
    border-radius: 10px;
}
.about-left:nth-child(1) {
    font-size: 14px;
}

.description{
    font-size: 14px !important;
    /* font-family:Quicksand,system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji */
    font-family:"Montserrat",sans-serif;
}

.row-section {
    display: flex;
    flex-direction: row;
    margin: 4rem;

}
.row{
    display: flex;
    flex-direction: column;
}

.col-right ul li {
    list-style-type: disc;
}

.col-right {
    width: 70%;
}

@media screen and (max-width:600px) {
    .row-section {
        display: flex;
        flex-direction: column;
    }
    
    .home-buttons{
        width: 100px !important;
    }
    .col-right {
        width: 100%;
    }

    .col-right .about-right {
        width: 100%;
    }

    .about-left {
        width: 70%;
        margin: 2rem;
    }

    .contact-section {
        display: flex;
        flex-direction: column;
    }

    .products-row {
        display: flex;
        flex-direction: column;
    }

    .products-img {
        width: 20rem;
        height: 8rem;
        padding: 2rem;
    }

}

.contact-section {
    display: flex;
    background-color: rgb(250, 202, 79);
    justify-content: space-between;
    padding: 4rem;

}

.contact-section-left {
    font-size: 16px;
}

.products-section {
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.products-row {
    display: flex;
    padding: 1rem;
    /* row-gap: 4rem; */
    background-color: rgb(35, 35, 35);
    width:100% !important;
}

.products-img {
    width: 28rem;
    height: 18rem;
    padding: 1rem;
    margin: 0 !important;
}

.row-section ul li {
    list-style-type: circle;
}

.col-left{
    /* background-color:#f9cc41; */
    width: 25%;
    border-radius: 1px;
    padding-top:1rem;
    margin: 0rem;
    margin-right: 1rem !important;

}
.col-right{
    margin: 2rem;
}

.row-section {
    padding: 2rem;
    display: flex;
    flex-direction: row !important;

    /* background-color: whitesmoke; */
}

.row-section:hover{
    /* box-shadow: 5px 10px 8px #888888; */
    
}

.about-right{
    font-size: 14px;
}
.about-right-text{
    font-size: 14px !important;
}
.home-buttons{
    background-color: #f9cc41;
    color: #333;
    margin-top: 2rem;
    color: inherit;
    text-transform: uppercase;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    border-radius: 0.125rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    padding: 0.84rem 2.14rem;
    font-size: .81rem; 
    border: none;
    justify-content: center;
    transition: transform 250ms;
}
.home-buttons:hover{
    transform: translateX(10px);
}
.card-body{
    font-size: 12px !important;
    font-family: 'Quicksand', sans-serif !important;
}