.profile-form {
    width: 90%;
    justify-content: end;
    color: rgb(34, 33, 33);
    /* background-color:whitesmoke; */
    height: auto;
    /* background: rgb(247,247,247);
background: linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(251,250,246,1) 0%, rgba(252,250,220,1) 100%); */


}

Form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
}

Button {
    column-gap: 2rem;
}

Button {
    color: rgb(35, 35, 35);
    color: black;
    margin: 0.5rem;
}

Form .profile-form-input {
    width: 10rem;
    height: 1.5rem;
}

.fieldset {
    background-color: rgb(251, 251, 251);
}

#outlined-read-only-input {
    font-size: 14px;

}

.profile-container {
    padding: 0.7rem;
    justify-content: space-between;
    font-size: 14px;
    /* background: rgb(247, 247, 247);
    background: linear-gradient(90deg, rgba(247, 247, 247, 1) 0%, rgba(251, 250, 246, 1) 0%, rgba(252, 250, 220, 1) 100%); */


}
.form-text-input{
    font-size: 12px;
}

.profile-buttons {
    margin: 0.1rem;
    padding:0;
    color: #333;
    text-transform: uppercase;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    border-radius: 0.125rem;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    padding: 0.84rem 2.14rem;
    font-size: .81rem;
    height: 2.5rem;
    background: rgb(249, 204, 65);
    background: linear-gradient(273deg, rgba(249, 204, 65, 1) 100%, rgba(255, 255, 255, 1) 100%); 
   
}

.profile-header-buttons{
    background: rgb(249, 204, 65);
    background: linear-gradient(273deg, rgba(249, 204, 65, 1) 100%, rgba(255, 255, 255, 1) 100%); 
    border-right: 1px solid whitesmoke; 
    border-bottom: 0;
    border-left:0;
    border-top: 0;
    padding-right:5rem;
    cursor: pointer; 
    color:#333;
}

.table-headers{
    background: rgb(249, 204, 65);
    background: linear-gradient(273deg, rgba(249, 204, 65, 1) 100%, rgba(255, 255, 255, 1) 100%);  
}


.profile-headers {
    display:flex;
    justify-content: space-between;
    padding: 1rem;
    background: rgb(249, 204, 65);
    background: linear-gradient(273deg, rgba(249, 204, 65, 1) 100%, rgba(255, 255, 255, 1) 100%); 
}

.profile-paper {
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    padding-bottom: 6.7rem;

}

.profile-contents {
    width: 100%;
    height: 100%;
    margin: 1rem;
    /* padding: 0rem; */
    /* justify-content: flex-end; */
}

.profile-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300;
    /* bgcolor: "background.paper", */
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgb(226, 226, 226) 56%, rgb(226, 225, 225) 100%);

    border: 0.2px solid #000;
    box-shadow: 24;
    padding: 4;
}
.table-data{
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: normal;
    line-height: 1.5;
}
.profile-header{
    font-family:"Helvetica Neue", Helvetica, Roboto,Arial,sans-serif ;
    font-weight:500;
    font-size: 1rem;
}

.fade-scale {
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;
  }
  
  .fade-scale.in {
    opacity: 1;
    transform: scale(1);
  }


.modal-form TextField {
    width: 100%;
}

.modal-input {
    width: 100%;
}

/* .modal-input:hover{
    border: ;

} */

.profile-form {
    border: 1px solid rgb(226, 226, 226);
    margin-top: 4rem;
}

.MuiInputLabel-root{
    font-size: 14px !important;
}
.MuiTypography-root{
    font-size: 14px;
}

.modal-title {
    background: rgb(240, 240, 240);
    background: linear-gradient(0deg, rgba(164, 164, 164, 1) 51%, rgba(198, 198, 198, 1) 100%);
    padding: 1rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
}

@media screen and (max-width:896px) {
    .profile-paper {
        display: flex;
        flex-direction: column;
        column-gap: 2rem;
    }

    Form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 0.2rem;
        column-gap: 0.2rem;
        overflow-x: auto;
        padding-top: 0.5rem;
        padding-left: 0rem;
        margin-left: 0rem;

        /* padding: initial; */
    }

    .profile-container {
        padding: 0rem;
    }

}
