.page-contents{
    width: 100%;
}
.my-clients-table{
    background: rgb(212,209,209);
    background: linear-gradient(3deg, rgba(212,209,209,1) 44%, rgba(255,255,255,1) 100%);
}
.my-clients-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 50%; */
    /* bgcolor: "background.paper", */
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgb(226, 226, 226) 56%, rgb(226, 225, 225) 100%);

    border: "2px solid #000";
    box-shadow: 24;
    padding: 4;
}
.modal-form{
    width: 100%;
}
.modal-input{
    width: 100%;
}

.form-row{
    width: 100%;
}

.container {
	width: 1024px;
	padding: 2em;
}

.bold-blue {
	font-weight: bold;
	color: #0277BD;
}
.table-title{
    color:#333333;
    font-weight: 500;
    font-size: 13px;
    margin:1rem;
}