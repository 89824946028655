/* *{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
} */
/* .hero-img {
    position: fixed;
    width: 100%;
    height: 100%;
}

.hero-content {
    background-image: url('../../../assets/images/bg14.jpg');
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 6rem;
    padding-bottom: 10rem;
    row-gap: 30rem !important;

} */

/* .content-left {
    display: flex;
    flex-direction: column;
    color: rgb(57, 56, 56);
    padding-top: 1rem;
    padding-left: 4rem;
    margin: 1rem;
    row-gap: 30px;
    font-size: 16px;

} */

.login-page {
    /* background-image: url("../../../../assets/images/bgi2.png"); */
    background-image: url("../../../assets/images/bg14.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
    padding-bottom: 14rem;
    margin-bottom: 2rem;
}

.content-left .content-header {
    font-size: 26px;
    font-weight: 800;
}

.content-right-img {
    margin-top: 2rem;
    width: 15rem;
    height: 10rem;
}

.content-header{
    color: #333 !important;
}

.hero-button {
    background-color: rgb(250, 202, 79);
    border: none;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    position: static;
}

.login-form {
    width: 35% !important;
    height: auto;
}
.login-text{
    color:#333;
    font-size: 13px !important;
    font-weight: 500;

}


.left {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    color: whitesmoke;

}

@media screen and (max-width:800px) {
    .left {
        display: none;
        /* padding-left: 0rem; */
    }

    /* .content-right{
        height: auto;
        width: 100%;
    } */
    .hero-content {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        padding-top: 1rem;
        width: 100%;
    }

    .form-holder {
        width: 100% !important;
        font-size: 12px !important;
    }

    .hero-img {
        display: none;
    }

}

.fade-in {
    animation: fadeIn 1s;
}

@keyframes fadeIn {

    0% {
        transform: translate(100%);
    }

    100% {
        transform: translate(0%);
    }


}

.animated {
    padding-top: 20px;
    margin-bottom: 15px;
    -webkit-animation-duration: 1s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

}

@-webkit-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);

    }

    40% {
        -webkit-transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);

    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}


.login-form {
    /* background-color: rgb(42, 40, 40); */
    color: white;
    margin: 2rem;
    padding: 2rem;
    opacity: 0.8;
    border-radius: 0.2rem;
    width: 90% !important;
    position: inherit;
}

.form-holder {
    margin-left: 2rem;
    width: 40%;
    padding-bottom: 4rem;
    margin-bottom: 5rem;
    /* background: rgb(247, 217, 48);
    background: linear-gradient(90deg, rgba(247, 217, 48, 1) 0%, rgba(51, 51, 51, 1) 0%, rgba(249, 204, 65, 1) 100%); */

}

.form-control {
    border: none;
    /* height: 4rem; */
}

.text-input {
    width: 16rem;
    padding: 1rem;
    margin: 0.5rem;
    border: none;
}

.form-button {
    width: 10rem;
    padding: 0.5rem;
    margin: 0.5rem;
}

.form-title {
    margin: 0.5rem;
}

Input:hover {}

Input:active {
    border: 2px solid rgb(250, 202, 79);
}