.header{
    background-color: #333;
    color: #f9f9f9;
    font-size: 11px !important;
}
.account-info{
    padding-top: 0.5rem;
    font-size: 13px;
}

.app-title{
    font-size: 20px;
    font-weight: 500;
}
.app-main-header{
    font-size: 20px;
}
.btn-primary.dropdown-toggle {
    background-color:black !important;
}
/* .modal-input{
    height: 4rem !important;
} */