.credit-transfer-modal {
    /* background-color: rgb(251, 251, 251); */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(247, 247, 247);
    background: linear-gradient(0deg, rgba(247, 247, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);
    border: 1px solid #333;
    opacity: 1;

}

.credit-transfer-modal-box {
    /* padding: 0.5rem; */
}

.credit-transfer-modal-title {
    background: rgb(233, 233, 233);
    background: linear-gradient(0deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
    padding: 1rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;


}

.credit-transfer-modal-buttons {
    background: rgb(129, 129, 129);
    background: linear-gradient(0deg, rgba(129, 129, 129, 1) 56%, rgba(93, 92, 92, 1) 100%);
    width: 50%;
}

.credit-transfer-modal-form-buttons {
    background: rgb(129, 129, 129);
    background: linear-gradient(0deg, rgba(129, 129, 129, 1) 56%, rgba(93, 92, 92, 1) 100%);
    width: 100%;
}

.credit-transfer-buttons {
    background: rgb(129, 129, 129);
    background: linear-gradient(0deg, rgba(129, 129, 129, 1) 56%, rgba(93, 92, 92, 1) 100%);
    width: 20%;
}

.credit-transfer-modal-box-title {
    background: rgb(233, 233, 233);
    background: linear-gradient(0deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
    color: #333;
    text-align: center;
    padding: 0.2rem;
}

@media screen and (max-width:689px) {
    .credit-transfer-modal {
        background-color: whitesmoke;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgb(226, 226, 226) 56%, rgb(226, 225, 225) 100%);
        border: 0.2px solid black;
        width: 90%;

    }
}

.table-headers{
    width: 100%;
    height:7rem;
    background: rgb(210, 210, 210);
    background: linear-gradient(0deg, rgba(210, 210, 210, 1) 12%, rgba(255, 255, 255, 1) 97%);
}
